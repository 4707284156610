section {
	max-width: 1200px;
	background-color: #fff;
	margin: 46px auto 0 auto;
	
	font-size: 17px;
	min-height: calc(100vh - 48px);
	height: 100%;
}

section > article {
	padding: 0 40px;
	margin: 40px 0;
	max-width: 1000px;
}

section p,
section ol {
	line-height: 1.4;
}

.hero {
	max-height: 400px;
	overflow: hidden;
}

.hero img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

section ol li {
	margin-bottom: 1rem;
}