
body, html {
	height: 100%;
}
html {

	--font-size: 14px;
	--green: #507f2f;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.01em;
	font-size: var(--font-size);
}
* {
	box-sizing: border-box;
}
p {
	margin: 0 0 1rem 0;
}
a {
	color: currentcolor;
}
h3 {
	margin: 0 0 12px 0;
	font-weight: 500;
	font-size: var(--font-size);
	color: #666;
}

h4 {
	margin: 0 0 8px 0;
	font-weight: 500;
	font-size: var(--font-size);
	color: #666;
}


#root {
	height: 100%;
	background-color: #f2f2f2;
	background-size: cover;
}

#root .mapboxgl-ctrl-group button {
	width: 50px;
	height: 46px;
	/* background-image: url('./images/trash-light.svg') !important; */
	background-size: 32%;
	background-position: center 28%;
	border-top: none !important;
	border-right: 1px solid #f2f2f2;
	position: relative;
}
#root .mapboxgl-ctrl-group button::after {
	position: absolute;
	bottom: 4px;

	left: 0;
	color: #666;
	text-align: center;
	font-size: 11px;
	right: 0;
}
#root .mapboxgl-ctrl-group button.mapbox-gl-draw_polygon::after {
	content: 'Building';
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_line::after {
	content: 'Road';
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_point::after {
	content: 'Marker';
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_trash::after {
	content: 'Delete';
}



#root .mapboxgl-ctrl-geocoder {
	position: absolute;
	right: 10px;
	margin: 0;
	top: 60px;
	height: 46px;
	width: 200px;
	min-width: 0;

}
#root .mapboxgl-ctrl-geocoder input {
	height: 100%;
}
.mapboxgl-ctrl-geocoder--icon {
	top: 14px !important;	
}


.mapboxgl-ctrl-top-left {
	right: 220px;
	left: auto !important;
	top: 60px !important;
	box-shadow: none !important;
}

.mapboxgl-ctrl-bottom-right {
	right: auto !important;
	left: 10px !important;
	top: 60px !important;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl {
	margin: 0 !important;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
	margin: 0;
	display: flex;
	box-shadow: none !important;

}
.mapbox-gl-draw_polygon {
	background-image: url('./images/draw-polygon-duotone.svg') !important;
}
.mapbox-gl-draw_line {
	background-image: url('./images/draw-line-duotone.svg') !important;
}

.mapbox-gl-draw_trash {
	display: none !important;
	border-right: none !important;
	background-image: url('./images/trash-duotone.svg') !important;
}

.mapbox-gl-draw_point {
	/* display: none !important; */
	background-image: url('./images/map-marker-alt-duotone.svg') !important;
}

.mapbox-gl-draw_combine {
	display: none !important;
	background-image: url('./images/object-group-duotone.svg') !important;
}

.mapbox-gl-draw_uncombine {
	display: none !important;
	background-image: url('./images/object-group-light.svg') !important;
}

.mapboxgl-ctrl-group {
	overflow: hidden;
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_line.active {
	background-color: #caffca;
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_polygon.active {
	background-color: #bfd3ff;
}

#root .mapboxgl-ctrl-group button.mapbox-gl-draw_point.active {
	background-color: #caffff;
}

.mapbox-gl-draw_ctrl-draw-btn.active,
.mapbox-gl-draw_ctrl-draw-btn.active:hover {
/* 	background-color: #c7f7de !important; */
}

hr {
	border: none;
	margin: 0;
	border-bottom: 1px solid #ddd;
}

.container {
	height: 100%;
	/* display: flex;
	flex-flow: column nowrap;	 */
}

.container > div {
	flex-grow: 1;
}

h1 {
	margin: 0 0 1rem 0;
	font-size: 2.6rem;
	font-weight: 500;
	color: var(--green);
}

h2 {
	font-weight: 500;
}

article {

}
