.display {
	padding: 1rem;
	background-color: #f2f2f2;
	border-radius: 4px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;	
	/* box-shadow: 0 0 10px rgba(0,0,0,0.4); */
	max-height: 400px;
	overflow: auto;

}

.row {
	margin: 0 0 8px 0;
	/* display: flex; */
	border-bottom: 1px solid #ddd;
	padding-bottom: 8px;
	/* flex-flow: row wrap; */
}
.date {
	font-size: 12px;	
	flex: 1 0 100%;
	color: #666;
	margin-bottom: 5px;
}

.row:nth-last-child(1) {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}
.tags {
	margin-top: 5px;
}

.tag {
	background: #dadada;
	font-size: 12px;
	font-weight: 500;
	border-radius: 3px;
	margin-right: 5px;
	padding: 1px 3px;
}