.layerControls {
	padding: 12px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
	margin-top: 10px;
}
.layerControls label {
	position: relative;
	width: 38px;
	height: 18px;
	margin-right: 1rem;
}

/* Hide default HTML checkbox */
.layerControls label input {
	opacity: 0;
	width: 0;
	height: 0;
}

.layerToggle {
	margin: 0 0 8px 0;
	display: flex;
	border-bottom: 1px solid #ddd;
	padding-bottom: 8px;

}

.layerToggle:nth-last-child(1){
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.layerToggleLabel {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
}

.layerToggleStatus {
	text-transform: uppercase;
	font-size: 12px;
	color: #999;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 200ms;
	transition: 200ms;
}

.slider::before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	transition: 200ms;
	transition: 200ms;
}

.layerControls input:checked + .slider {
	background-color: #50a2dc;
}

.layerControls input:focus + .slider {
	box-shadow: 0 0 1px #50a2dc;
}

.layerControls input:checked + .slider::before {
	transform: translateX(20px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round::before {
	border-radius: 50%;
}