.selectElement,
.buttonElement,
.inputElement,
.textareaElement,
.largeInputElement {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;

}
.selectElement {
	padding: 5px;
	border: 0;
	background-color: #f2f2f2;
}

.required {
	flex: 1 0 100%;
	text-align: right;
	font-size: 12px;
	color: #f44336;
	font-weight: 500;
}

.buttonElement {
	cursor: pointer;
	padding: 5px 10px;
	font-size: 14px;
	border: none;
	border-radius: 3px;
	font-weight: 500;
	opacity: 0.8;
}

.buttonElement:hover {
	opacity: 1;
}

.buttonElement.delete {
	background-color: #ff4f34;
	color: #fff;
}

.buttonElement.save {
	background-color: #3fbb3f;
	color: #fff;
}

.buttonElement.facebook {
	background-color: #1877f2;
	color: #fff;
}

.buttonElement.google {
	background-color: #DB4437;	
	color: #fff;
}

.buttonElement i {
	margin-right: 5px;
}

.textareaElement {
	resize: none;
	margin-top: 8px;
	width: 100%;
	background-color: #f2f2f2;
	border: none;
}
.inputElement,
.textareaElement {
	border: 1px solid #ddd;
	padding: 3px 5px;
	border-radius: 2px;
	min-width: 200px;
	font-size: 13px;
	margin-bottom: 0;
}

.largeInputElement {
	border: 1px solid #ddd;
	padding: 6px 8px;
	border-radius: 2px;
	min-width: 200px;
	font-size: 14px;
	margin-bottom: 10px;

}

.selectElement,
.textareaElement {
	font-family: Menlo;
	font-size: 11px;
}