.map {
	width: 100vw;
	height: 100vh;
	position: relative;
}

.sidebar {
	
	position: absolute;
	right: 10px;
	top: 106px;
	width: 360px;

}

.form {
	margin: 1rem 0;
	padding: 1rem;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
