.nav {
	
	position: absolute;
	background-color: #fff;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 46px;
/* 	top: 10px;
	right: 10px; */
	z-index: 2;
	/* border-radius: 4px; */

	
	font-weight: 500;
	
}
.logo {
	padding-top: 9px;
	padding-left: 12px;
	flex: 0 0 39px;	
	background-color: #507f2f40;

}
.logo img {
	width: 100%;
}

.nav > div {
	height: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.nav ul {
	margin: 0;
	padding: 0;
	flex-grow: 1;
	list-style-type: none;
	/* color: #fff; */
	display: flex;
}

nav ul li {

}

.nav ul a {
	display: block;
	padding: 14px 20px;
	color: currentcolor;
	text-decoration: none;
	height: 100%;
}

.nav ul a.active {
	background-color: #f2f2f2;
}

ul.secondaryNav {
	flex-grow: 0;
	color: #666;
}
ul .homeLink a {
	padding-top: 10px;
}

.homeLink {
	background-color: #507f2f40;
	font-size: 20px;
	font-weight: 800;
	color: var(--green);
}