.layerControls {
	padding: 12px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
	margin-top: 10px;
	position: fixed;
	left: 20px;
	bottom: 20px;
}

.row {
	margin: 0 0 6px 0;
	display: flex;
	border-bottom: 1px solid #ddd;
	padding-bottom: 8px;
	align-items: center;
}
.row:nth-last-child(1) {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom:  0;
}

.line {
	width: 30px;
	height: 6px;
	border-radius: 4px;
	margin-right: 20px;

}

.building {
	margin-right: 30px;
	width: 18px;
	height: 18px;
	background-color: rgba(0,0,255, 0.4);
	border: 2px solid #3758e5;
}

.otherBuilding {
	margin-right: 30px;
	width: 18px;
	height: 18px;
	background-color: rgba(255,87,34, 0.4);
	border: 2px solid #f44336;
}