
.row {
	padding: 6px 0;
}

.login > div {
	/* width: 340px; */
}

.login form {
	position: relative;
}

.login hr {
	margin: 10px 0;
}

.login input {
	width: 100%;
}

.loginSignup {
	margin-top: 2rem;
	display: flex;
	justify-content: space-between;
}

.loginSignup > div {
	flex: 0 1 350px;
}