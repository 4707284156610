.formRow {
	padding: 6px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;
}

.formRow label {
	flex: 0 0 50%;
}

.formRow select {
	flex: 0 0 50%;
	max-width: 150px;	
}

.description {
	display: block;
}

.formRow textarea {

}

.formRow label {

}

.action {
	 margin-top: 8px; 
}

.action button {
	
	margin-right: 10px;
}

.name input {
	flex: 0 1 150px;
	min-width: 0;
	background-color: #f2f2f2;
}

.form {
	margin: 10px 0 0 0;
	padding: 12px;
	background-color: #fff;
	border-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;	
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
